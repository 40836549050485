import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import loadable from '@loadable/component';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { RouteFormatter } from '../../routes';
import { isUnverified } from '../../consts/user.consts';
import { SIGN_UP_SRC } from '../../consts/spot.consts';
import RenderSpotBadge from '../../pages/spot-view-page/render-spot-badge';
import { AUTH_TESTS } from '../../pages/spot-view-page/auth-test.const';
import MobileSubPage from '../mobile-sub-page';
import ModalMobile from '../modal-mobile';
import SniffButton from '../sniff-button';
import SortReviews from './sort-reviews';
import SvgChevronRight from 'src/assets/svg/SvgChevronRight.svg';
import SvgStar from 'src/assets/svg/SvgStar.svg';
import './index.scss';

const AllReviewsClient = loadable(() => import('./all-reviews'));
const AllReviewsSSR = loadable(() => import('./all-reviews-ssr'));
const Review = loadable(() => import('../review'));

const CookieService = require('../../services/cookie.service');

const MobileReviews = ({ allModal, toggleAllModal, children }) => {
    return (
        <MobileSubPage show={allModal} onBack={toggleAllModal} title="All reviews" mBottom={96}>
            {children}
        </MobileSubPage>
    );
};

const DesktopReviews = ({ allModal, toggleAllModal, children }) => {
    return (
        <ModalMobile isOpen={allModal} toggle={toggleAllModal} title="All reviews" className="all-reviews-desktop">
            {children}
        </ModalMobile>
    );
};

const ListingReviews = ({
    reviews: reviewsC = [],
    reviewsCount,
    rating,
    moreHandler,
    wait,
    userDetails,
    spotId,
    host,
    spot,
    isMobile,
    sortReviews,
    changeSort,
    handleSniffpassReviewBadge,
    handleMembershipReviewBadge,
    useRefSSR,
    useStateSSR,
    useEffectSSR,
    useAddReplySSR,
    useDeleteReplySSR,
    useHandleUpvoteSSR,
    useGetSpotReviewsSSR,
    useOnScreenSSR,
    dataReviews,
    sortBy,
    serverSSR,
    mutateViewAuthSpot,
}) => {
    const AllReviews = useStateSSR ? AllReviewsSSR : AllReviewsClient;
    const [allModal, setAllModal] = useState(false);
    const [sortOpen, setSortOpen] = useState(false);
    const [guestRevPhotoOpen, setguestRevPhotoOpen] = useState(null);
    const [ReviewModal, setReviewModal] = useState(() => MobileReviews);

    const reviews = dataReviews || reviewsC;

    const toggleSortOpen = () => setSortOpen(!sortOpen);

    const toggleAllModal = async () => {
        const newShow = !allModal;
        const isUnver = isUnverified(userDetails);

        // guest auth test
        if (!userDetails?.id || isUnver) {
            const re = `${window.location.pathname}#${AUTH_TESTS.ALL_REVIEWS}`;

            if (isUnver) {
                CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT, window.location.href);
                CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT_ONBOARD, re);
                window.location = RouteFormatter.finishYourAccount();
                return;
            }

            CookieService.default.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.SPOT_DETAILS_WEB, { expires: 1 });
            CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT, re);
            window.location = RouteFormatter.signUp();
            return;
        }

        setAllModal(newShow);
    };

    const onChangeSort = (v) => {
        changeSort(v);
        toggleSortOpen();
    };

    const ReviewModalComp = (
        <ReviewModal {...{ allModal, toggleAllModal }}>
            <AllReviews
                isSpotDetail
                noRichText
                {...{
                    reviewsCount,
                    rating,
                    wait,
                    userDetails,
                    spotId,
                    host,
                    sortReviews,
                    onChangeSort,
                    sortOpen,
                    toggleSortOpen,
                    useRefSSR,
                    useStateSSR,
                    useEffectSSR,
                    useGetSpotReviewsSSR,
                    useOnScreenSSR,
                    isMobile,
                    useAddReplySSR,
                    useDeleteReplySSR,
                    useHandleUpvoteSSR,
                    changeSort,
                    sortBy,
                    handleSniffpassReviewBadge,
                    handleMembershipReviewBadge,
                    spot,
                }}
            />
        </ReviewModal>
    );

    useEffect(() => {
        if (!isMobile) {
            setReviewModal(() => DesktopReviews);
        }

        // guest auth test
        const urlHash = window.location.hash.substring(1);
        const hashSplit = urlHash.split('-');
        if (userDetails?.id && urlHash == AUTH_TESTS.ALL_REVIEWS) {
            setAllModal(true);
        } else if (userDetails?.id && hashSplit?.length > 1 && hashSplit[0] == AUTH_TESTS.GUEST_REVIEW_PHOTO) {
            setguestRevPhotoOpen(hashSplit[1]);
        }
    }, []);

    return (
        <section id="reviews">
            <div className="comments px-2 px-md-0">
                <RenderSpotBadge {...{ spot }} />
                {reviewsCount == 0 && spot?.new && (
                    <div className="mt-3 mb-4">
                        <p className="snif-m1 snif-semibold mb-1">No reviews yet</p>
                        <p className="snif-p">
                            This spot hasn't gotten any reviews by guests since it was first published on{' '}
                            {moment(spot.publishedAt).format('MMMM D, YYYY')}.
                        </p>
                    </div>
                )}
                {reviewsCount > 0 && (
                    <>
                        <div className="rev-head">
                            <h2
                                className="title-reviews snif-m1 snif-semibold mt-2 mb-3"
                                onClick={isMobile ? toggleAllModal : () => {}}
                                id={AUTH_TESTS.MORE_REVIEWS}
                            >
                                <div>
                                    <SvgStar className="star-reviews" /> {parseFloat(rating).toFixed(1)}
                                    <span className="reviews-count snif-medium">{` (${reviewsCount} ${
                                        reviewsCount > 1 ? 'Reviews' : 'Review'
                                    })`}</span>
                                </div>
                                <SvgChevronRight className="d-md-none chevron-reviews" />
                            </h2>
                            {reviewsCount > 3 && (
                                <div className="all-reviews snif-p snif-medium text-primary d-none d-md-block" onClick={toggleAllModal}>
                                    See all reviews
                                </div>
                            )}
                        </div>
                        <SortReviews {...{ sortOpen, toggleSortOpen, onChangeSort, sortReviews }} />
                    </>
                )}
                {reviews.map((review, i) => (
                    <Review
                        key={i}
                        isSpotDetail
                        noRichText
                        {...{
                            ...review,
                            userDetails,
                            spotId,
                            host,
                            handleSniffpassReviewBadge,
                            handleMembershipReviewBadge,
                            useStateSSR,
                            isMobile,
                            useAddReplySSR,
                            useDeleteReplySSR,
                            useHandleUpvoteSSR,
                            changeSort,
                            sortBy,
                            spot,
                            serverSSR,
                            guestRevPhotoOpen,
                            mutateViewAuthSpot,
                        }}
                    />
                ))}
                {reviewsCount > reviews.length && !wait ? (
                    <div className="row mb-3">
                        <div className="col-md-12">
                            <SniffButton color="secondary" size="lg" onClick={moreHandler}>
                                Load more reviews
                            </SniffButton>
                        </div>
                    </div>
                ) : null}
                {wait ? (
                    <div className="col-md-12 mb-3 text-center">
                        <div className="loading" />
                    </div>
                ) : null}
                <hr />
                {ReviewModalComp}
            </div>
        </section>
    );
};

export default ListingReviews;
